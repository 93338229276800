@charset "UTF-8";

a, a:link {
    color: inherit;
    text-decoration: none;
}

/*a:hover {*/
/*    color: #3f51b5;*/
/*}*/

td a {
    display: block;
}

tr:hover {
    color: #ffffff;
    background-color: #f6f6f6;
}

#datetime-planned,
#datetime-shipped,
#datetime-ready,
#datetime-production,
#datetime-work,
#datetime-account {
    font-size: 14px !important;
}